import { sortByDate } from "@rocket-resume/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useResumes } from "@/client/services/resume";
import { BaseListItem } from "./_components/base-item";
import { ResumeListItem } from "./_components/resume-item";
import { useResumePreview } from "@/client/services/resume/preview";
import { useNavigate } from "react-router-dom";
import { ResumePreview } from "../grid/_components/resume-preview";

export const ListView = () => {
  const { resumes, loading } = useResumes();
  const [resumePreview, setResumePreview] = useState<Boolean>(false);
  const [selectedRId, setSelectedRId] = useState<string | null>(null);
  const { url } = useResumePreview(selectedRId ? selectedRId : '');
  const navigate = useNavigate();

  useEffect(() => {
      if (resumes && resumes.length > 0 && !selectedRId) {
        setSelectedRId(resumes[0].id);
        setResumePreview(true)
      }
  }, [resumes]);

  const handleClosePreview = () =>{
    setSelectedRId(null)
    setResumePreview(false)
  }

  const handlePreview = (template: string) => {
    setResumePreview(true)
    setSelectedRId(template); 
  };

  const onOpen = () => {
    navigate(`/builder/${selectedRId}`);
  };

  return (
    <div className="flex gap-8 justify-between h-[86vh]">
      <div className="w-[100%] md:w-[35%] overflow-y-auto scrollbar-hide">
        <div className="grid gap-y-4">
          {loading &&
            Array.from({ length: 4 }).map((_, i) => (
              <div
                key={i}
                className="duration-300 animate-in fade-in"
                style={{ animationFillMode: "backwards", animationDelay: `${i * 300}ms` }}
              >
                <BaseListItem className="bg-secondary/40" />
              </div>
            ))}

          {resumes && (
            <AnimatePresence>
              {resumes
                .sort((a, b) => sortByDate(a, b, "updatedAt"))
                .map((resume, index) => (
                  <motion.div
                    key={resume.id}
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: (index + 2) * 0.1 } }}
                    exit={{ opacity: 0, filter: "blur(8px)", transition: { duration: 0.5 } }}
                  >
                    <ResumeListItem resume={resume} handleClosePreview={handleClosePreview} handlePreview={handlePreview} isSelected={resume.id === selectedRId} />
                  </motion.div>
                ))}
            </AnimatePresence>
          )}
        </div>
      </div>
      {
        resumePreview && url && 
        <ResumePreview handleClosePreview={handleClosePreview} url={url} selectedRId={selectedRId} />
      }
    </div>
  );
};
